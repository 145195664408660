.bloglisting-wrapper .blog-card {
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 5px 14px 0px rgba(8, 15, 52, 0.04);
}

.bloglisting-wrapper .blog-card img {
    object-fit: cover;
    border-radius: 20px;
    padding-bottom: 10px;
}

.bloglisting-wrapper .blog-card .card-text {
    display: flex;
    flex-direction: row;
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 7px;
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 10px;
}

.bloglisting-wrapper .blog-card h3 {
    color: #000000;
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.bloglisting-wrapper .blog-card p{
    color: #808080;
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.bloglisting-wrapper .blog-card .card-btn{
    color: #04153A;
    font-size: 2.0rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.blogs-container .btn-see .see-type{
    color: #04153A;
    font-size: 2.0rem;
    font-weight: 600;
    margin-bottom: 10px;
    width: 100px;
}

 .blogdetails-header {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/dev-head.webp') center/cover no-repeat;
    margin-bottom: 40px;
    flex-direction: column;
    text-align: center;
}
 .blogdetails-header h2 {
    color: #ffffff;
    font-size: 3.8rem;
    font-weight: 800;
    text-align: center;
}

.blogdetails-wrapper .blog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blogdetails-wrapper .blog-content .blog-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.blogdetails-wrapper .blog-content .blog-header p {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 500;
}

.blogdetails-wrapper .blog-content .blog-header h3 {
    color: #1C232D;
    font-size: 3.2rem;
    font-weight: 800;
    margin-bottom: 5px;
}

.blogdetails-wrapper .blog-content .blog-header h5 {
    color: #556987;
    font-size: 1.5rem;
    font-weight: 700;
}

.blogdetails-wrapper .blog-content .blog-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    object-fit: contain;
    
    
}
.blogdetails-wrapper .blog-content .blog-img img{
    border-radius: 30px;
    
    
}

.blogdetails-wrapper .blog-content .image-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    width: 60%;
}

.blogdetails-wrapper .blog-content .image-detail p{
    color: #556987;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
}

.blogdetails-wrapper .blog-content .blog-wrapper{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 60%;
    padding: 10px 0;
    margin: 10px 0;
    /* border-top: 1px solid #EEF0F3;
    border-bottom: 1px solid #EEF0F3; */
}

.blogdetails-wrapper .blog-content .blog-wrapper h4{
    color: #333F51;
    font-size: 2.6rem;
    font-weight: 600;
    text-align: left;
}

.blogdetails-wrapper .blog-content .blog-wrapper p{
    color: #556987;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: justify;
}

.blogdetails-wrapper .blog-content .blog-wrapper .qoute{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border-left: 1px solid #000;
    padding: 20px 26px;
    margin: 10px;
    gap: 10px;
}

.blogdetails-wrapper .blog-content .blog-wrapper .qoute p{
    color: #333F51;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: left;
}

.blogdetails-wrapper .blog-content .blog-wrapper .qoute p:last-child{
    color: #8896AB;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: left;
}

.blogdetails-wrapper .blog-content .blog-wrapper .image-wraper{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
    gap: 10px;
}


.blogdetails-wrapper .blog-content .blog-wrapper .image-wraper img{
    width: 100%;
    height: 400px;
}

.blogdetails-wrapper .blog-content .blog-wrapper .image-wraper h6{
    color: #8896AB;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: left;
}

.blogdetails-wrapper .blog-content .icons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
.blogdetails-wrapper .blog-content .icons-row .copy-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px dotted #D5DAE1; 
    padding: 10px; 
    font-size: 1.4rem;
    color: #556987;
    border-radius: 10px;
}
.blogdetails-wrapper .blog-content .icons-row .copy-icon .icons{ 
    font-size: 20px;
    color: #BBC3CF;
}

.blogdetails-wrapper .blog-content .icons-row  .icon {
    font-size: 20px; 
    border: 1px solid #D5DAE1; 
    padding: 10px; 
    color: #556987;
    border-radius: 5px;
}

/* Blogs Section Styling */

.blogs-container .blog-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 60px 20px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
.blog-box h6{
    color: #556987;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: justify;
}

@media (max-width: 768px) {
    .blog-box:nth-child(odd) {
        border-right: 1px solid #ccc;
    }
    .blogdetails-header h2{
        font-size: 2.8rem;
    }
    .blogs-container .blog-box {
        
        border-right: none;
    }
    .blogdetails-wrapper .blog-content .blog-img img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        object-fit: contain;
        
        
    }

}

.blogs-container .blog-box h3 {
    color: #04153A;
    font-size: 2.0rem;
    font-weight: 700;
}

.blogs-container .blog-box p {
    color:#6F6F6F;
    font-size: 1.2rem;
    font-weight: 500;
}

.blogs-container button {
    width: 200px;
    height: 50px;
    background-color: #04153A;
    color: #fff;
    border-radius: 6px;
    font-size: 1.6rem;
    margin: 0 auto;
}