.intro-wrapper .intro-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.intro-wrapper .intro-head {
    display: flex;
    justify-content: space-between;
}

.intro-wrapper .intro-content h1 {
    color: #04153A;
    font-size: 3.8rem;
    font-weight: 700;
}


.intro-wrapper .intro-content .intro-text {
    color: #445164;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 32.8px;
    text-align: justify;
}

.intro-wrapper .intro-content .trust-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}



.intro-wrapper .intro-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.intro-wrapper .center-img{
   display: flex;
   justify-content: center;
   align-items: center;
}
.intro-wrapper .center-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.feature-wrapper {
    background: url("../../../assets/expertise.webp") center/cover no-repeat;
    height: auto;
    width: 100%;
    margin-top: 50px;
    padding: 80px 50px;
  }
  .feature-wrapper .tech-feature{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .feature-wrapper .feature-head h1 {
    font-size: 1.7rem;
    font-weight: 500;
    color: #fffcfc;
    width: 80%;
    text-align: center;
  }
  .feature-wrapper .feature-head p {
    color: white;
    font-weight: 900;
    font-size: 4rem;
    text-align: center;
  }
  .feature-wrapper .feature-head{
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .feature-wrapper .tech-feature .icon-card-feature h1 {
    color: white;
    font-weight: 700;
    font-size: 2.0rem;
    text-align: center;
  }
  .feature-wrapper .tech-feature .icon-card-feature h6 {
    color: white;
    font-weight: 700;
    font-size: 3.0rem;
    text-align: center;
  }
  .feature-wrapper .tech-feature .icon-card-feature h2 {
    color: white;
    font-weight: 500;
    font-size: 1.7rem;
    text-align: center;
  }
  .feature-wrapper .tech-feature .icon-card-feature {
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border: 1px solid rgb(77, 67, 86);
    row-gap: 5px;
    width: 300px;
    height: 230px;
  }