.pricing-wrapper .pricing-card {
    background-color: #04153A;
    border: none;
    border-radius: 15px;
    padding: 30px 40px;
    color: #fff;
    position: relative;
}

.pricing-wrapper .pricing-card .card-heading {
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
}

.pricing-wrapper .pricing-card .styled-arrow {
    width: 65px;
    height: 65px;
    position: absolute;
    left: 125px;
}

.pricing-wrapper .pricing-card .styled-text {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: Sacramento;
}

.pricing-wrapper .pricing-card .price {
    font-size: 2.4rem;
    font-weight: 500;
    padding: 5px 10px ;
    border: 1px solid #fff;
    border-radius: 5px;
}

.pricing-wrapper .pricing-card > p {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 20px 0;
}

.pricing-wrapper .pricing-card .quote-btn {
    width: 200px;
    height: 50px;
    background-color: #fff;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 1.6rem;
    font-weight: 600;
}

.pricing-wrapper .pricing-card .quote-btn:hover {
    color: #04153A;
}

.pricing-wrapper .pricing-detail-box {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: #ffffff;
    border-radius: 0 10px 10px 0;
    gap: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 768px) {
    .pricing-wrapper .pricing-detail-box {
        border-radius: 0 0 10px 10px;
    }
}

.pricing-wrapper .pricing-detail-box .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.pricing-wrapper .pricing-detail-box .buttons-wrapper button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 250px;
    background-color: #04153A;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 500;
}

.pricing-wrapper .pricing-detail-box .items {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.pricing-wrapper .pricing-detail-box .items .items-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.pricing-wrapper .pricing-detail-box .items .items-row p {
    color: #04153A;
    font-size: 1.6rem;
    font-weight: 500;
}
