.portfolio-sec .portfolio-head{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.portfolio-sec .sec-heading {
    font-size: 2.8rem;
    font-weight: 700;
    color: #04153A;
  }
  .breadcrumb-head {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../../assets/dev-head.webp') center/cover no-repeat;
    margin-bottom: 40px;
    flex-direction: column;
    text-align: center;
}

.breadcrumb-head h2 {
    color: #fff;
}
.breadcrumb-head  p {
    font-size: 1.7rem;
    font-weight: 600;
    color: #fff;
}

  .testimonial {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.testimonial-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* margin-right: 20px; */
    object-fit: cover;
}

.testimonial-content {
    max-width: 600px;
}

.testimonial-name {
    font-size: 1.7em;
    font-weight: bold;
    margin: 0;
}

.testimonial-title {
    font-size: 1.5rem;
    color: #555;
    margin: 5px 0;
}

.testimonial-quote {
    font-size: 1.3rem;
    font-style: italic;
    color: #333;
}
.testimonial-list {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.testimonial-list-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}
.contactus-wrapper {
    background: url('../../../assets/dev-head.webp') center/cover no-repeat;
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .contactus-wrapper .init-wrapper p {
  color: white;
  font-weight: 900;
  font-size: 2.5rem;
  text-align: center;
  }
  .contactus-wrapper .init-wrapper {
   gap: 20px;
   display: flex;
   align-self: center;
    }
  
  .contactus-wrapper .init-wrapper .contactus-head {
    font-size: 1.6rem;
    font-weight: 400;
    color: #fffcfc;
    text-align: center;
  
  }
  .contactus-wrapper .init-wrapper button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;
    background-color: #ffff;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
  }


  
  @media (max-width: 768px) {
    .expertise-wrapper {
      height: auto;
    }
    .head-img img {
      height: 200px;
    }
  }
  