.vision-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f6f6f6;
    border: none;
    border-radius: 20px;
    padding: 30px;
    align-items: start; 
    text-align: start;
    height: 380px; 
  }
  
.vision-card .icons {
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    font-size: 2.0rem; 
}

.vision-card  h3{
   font-size: 2.2rem;
   font-weight: bold;

}

.vision-card  p{
    font-size: 1.6rem;
    font-weight: 500;
    text-align: justify;
}

