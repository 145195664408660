.whatsapp-floating-icon {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    border-radius: 10px;
    font-size: 4rem;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    color: #fff;
}
  
.whatsapp-floating-icon:hover {
    background-color: #02143A;
}
  