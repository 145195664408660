.portfolio-sec .portfolio-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.portfolio-sec .sec-heading {
  font-size: 2.8rem;
  font-weight: 700;
  color: #04153a;
}
.portfolio-sec .sec-text {
  padding-bottom: 10px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
}

.portfolio-sec .cards-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
.logo-container {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px;
  gap: 30px;
  height: 100px;
  /* background-color:rgba(4, 21, 58, 1); */
}

.logo-container::-webkit-scrollbar {
  display: none;
}
.partner-logo {
  width: 120px;
  height: 50px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.partner-logo:last-child {
  margin-right: 0;
}
.partner-logo:first-child {
  margin-left: 0px;
}

.partner-logo:hover {
  transform: scale(1.05);
}

.portfolio-card {
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 10px;
  justify-content: center;
}

.portfolio-card h3 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
}
.portfolio-card .portfolio-detail {
  flex-direction: column;
  display: flex;
}
.portfolio-card .portfolio-detail .portfolio-btn {
  padding: 5px;
  width: 150px;
  border-radius: 10px;
  align-self:flex-start;
}

.portfolio-card p {
  padding-bottom: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: justify;
}
.head-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
}

.partner-wrapper {
  background-color: #04153a;
  color: #fff;
}

.partner-wrapper .partner-content {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partner-wrapper .partner-content h3 {
  font-size: 4rem;
  text-align: center;
  width: 80%;
}

.partner-wrapper .partner-content p {
  font-size: 1.6rem;
  width: 60%;
  text-align: center;
}

.partner-wrapper .partner-content .social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.partner-wrapper .partner-content .social img {
  width: 48px;
  height: 48px;
}

.partner-wrapper .partner-content .social h4 {
  font-size: 2rem;
}

.partner-wrapper .partner-content .social .social-icons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.partner-wrapper .partner-content .social .social-icons i {
  font-size: 1.6rem;
}

.partner-wrapper .partner-form {
  padding: 50px;
  height: auto;
}

@media (max-width: 768px) {
  .expertise-wrapper {
    height: auto;
  }
  .partner-logo {
    max-width: 80%;
    padding: 5px;
  }
  .head-img img {
    height: 200px;
  }
}
@media (max-width: 576px) {
  .partner-logo {
    max-width: 70%;
  }
  .partner-wrapper .partner-content h3 {
    font-size: 4rem;
    width: 100%;
  }

  .partner-wrapper .partner-content p {
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
  }
  .partner-wrapper .partner-content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
