.contact-wrapper {
    margin-top: 50px;
    background-color: #04153A;
    color: #fff;
}

.contact-wrapper .contact-content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-wrapper .contact-content h3 {
    font-size: 4rem;
    text-align: center;
    width: 80%;
}

.contact-wrapper .contact-content p {
    font-size: 1.6rem;
    width: 60%;
    text-align: center;
}

.contact-wrapper .contact-content .socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.contact-wrapper .contact-content .socials img {
    width: 48px;
    height: 48px;
}

.contact-wrapper .contact-content .socials h4 {
    font-size: 2rem;
}

.contact-wrapper .contact-content .socials .social-icons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.contact-wrapper .contact-content .socials .social-icons i {
    font-size: 1.6rem;
}

.contact-wrapper .contact-form {
    padding: 50px;
    height: auto;
}
@media (max-width: 580px) {
    .contact-wrapper .contact-content {
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* border: 1px solid white; */
    }
    .contact-wrapper .contact-form {
        padding: 0px;
        height: auto;
    }
    .contact-wrapper .contact-content h3 {
        font-size: 4rem;
        width: 100%;
    }
    
    .contact-wrapper .contact-content p {
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
    }
  }