.culture-wrapper .culture-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.culture-wrapper .culture-content h1 {
    color: #04153A;
    font-size: 4.0rem;
    font-weight: 700;
    line-height: 50px;
    text-align: justify;
}


.culture-wrapper .culture-content .culture-text {
    color: #445164;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 32.8px;
    text-align: justify;
}





.culture-wrapper .culture-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}


/* CultureList CSS */


.list {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
}

.list i {
    size: 2.6rem;
    color: #d3f1ff;
}

.list {
    color: #000;
    font-size: 1.4rem;
    font-weight: 400;
}
@media (max-width:580px) {
    .culture-wrapper .culture-content h1 {
        color: #04153A;
        font-size: 3.0rem;
        font-weight: 700;
        line-height: 50px;
    }
    
}