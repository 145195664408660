.introduction-wrapper .introduction-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.introduction-wrapper .introduction-head {
    display: flex;
    justify-content: space-between;
}

.introduction-wrapper .introduction-content h1 {
    color: #04153A;
    font-size: 3.8rem;
    font-weight: 700;
}


.introduction-wrapper .introduction-content .introduction-text {
    color: #445164;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 32.8px;
    text-align: justify;
}
.introduction-wrapper .introduction-content .introduction-link {
    color: blue;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 32.8px;
    text-align: justify;
}


.introduction-wrapper .introduction-content .trust-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}



.introduction-wrapper .introduction-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
}
.introduction-wrapper .center-img{
   display: flex;
   justify-content: center;
   align-items: center;
}
.introduction-wrapper .center-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.store-buttons {
    margin-top: 1px;

  }
  
  .store-button {
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    /* color: inherit;
    background-color: black; */
    /* padding: 10px;
    border-radius: 10px; */
  }
  
  .store-button span {
    margin-left: 10px;
    font-size: 1.4rem;
    color: white;
  }
  .store-button img {
   width: auto;
   height: 50px;
   object-fit:contain;
  }
  .introduction-link button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 130px;
    background-color: #04153A;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 500;
}
/* .disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none; 
  }
   */