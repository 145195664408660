.footer-wrapper {
    background-color: #04153A;
    color: #fff;
    padding: 50px;
    text-align: center;
}

.footer-wrapper .footer-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-wrapper .footer-section h3 {
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
}

.footer-wrapper .footer-section p {
    font-size: 1.4rem;
    font-weight: 400;
}

.footer-wrapper .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-wrapper .footer-section ul li {
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 10px;
}
.footer-wrapper .footer-section .quote-btnn {

    font-size: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    color: white;
}
.navbar .navbar-nav .nav-item {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 10px;
}
.footer-wrapper .footer-section .contact-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.footer-wrapper .socialcard {
    background-color: #fff;
    height: auto;
    width: auto;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;
    /* gap: 7px; */
}

.footer-wrapper .socialcard h5 {
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
}

.footer-wrapper .socialcard .socialicons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    gap: 10px;
    color: #fff;
}


.footer-wrapper .socialcard .socialicons i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
}

@media (min-width: 680px) {
    .footer-wrapper {
        text-align: start;
    }
    /* .bordered {
        border-left: 2px solid #fff;
    } */
    .footer-wrapper .footer-section .contact-details {
        justify-content: start;
    }
}