.header-wrapper .header-content {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.header-wrapper .header-content h1 {
    color: #04153A;
    font-size: 6rem;
    font-weight: 700;
    line-height: 80px;
}

.header-wrapper .header-content h1 span {
    color: #fff;
    background: #04153A;
    border-radius: 5px;
    padding: 0 10px;
}

.header-wrapper .header-content .header-text {
    color: #445164;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: justify;
    line-height: 32.8px;
}

.header-wrapper .header-content .trust-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.header-wrapper .header-content .trust-btn-wrapper .trust-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.header-wrapper .header-content .trust-btn-wrapper .trust-text p{
    color: #04153A;
    font-size: 1.4rem;
    font-weight: 700;
}

.header-wrapper .header-content .numbers {
    background-color: #F7F8F9;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.header-wrapper .header-content .numbers .number-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-wrapper .header-content .numbers .number-card h3 {
    color: #2A3342;
    font-size: 3.0rem;
    font-weight: 700;
}

.header-wrapper .header-content .numbers .number-card p {
    color: #556987;
    font-size: 1.4rem;
    font-weight: 500;
}

.header-wrapper .header-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}