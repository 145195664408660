* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

p {
    margin: 0;
}

html {
    font-size: 62.5%;
    font-family: 'Manrope', sans-serif;
}

body {
    background-color: #fff;
    font-family: 'Manrope', sans-serif;
}

body button {
    outline: none;
    border: none;
    font-size: 1.6rem;
}

body a {
    color: #fff;
    text-decoration: none;
}

body a:visited {
    color: #fff;
}

body a:hover {
    color: #fff;
}

.star-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-top: 50px;
}

.star-wrapper .star {
    width: 15px;
    height: 15px;
    object-fit: cover;
}

.star-wrapper p {
    color: #000;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.32px;
}

.section-heading {
    padding: 10px 0;
    color: #000000;
    font-size: 3.6rem;
    font-weight: 700;
    text-align: center;
}

.section-text {
    text-align: center;
    color: #000000;
    font-size: 1.4rem;
    font-weight: 400;
    width: 80%;
    margin: 0 auto;
}

.CTA {
    width: 150px;
    padding: 12px 24px;
    border-radius: 4px;
    background: #02143A;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
}

.CTA:hover {
    background: #02143A;
    color: #fff !important;
}