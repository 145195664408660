.serviceslisting-wrapper .section-heading {
  font-size: 2.8rem;
  font-weight: 700;
  color: black;
}
.serviceslisting-wrapper .section-text {
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
}

.casestudy-wrapper .cards-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.casestudy-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: whitesmoke;
  border-radius: 10px;
}

.casestudy-card .casestudy-card-content h3 {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}
.casestudy-card .casestudy-card-content {
  flex-direction: column;
  display: flex;
  gap: 5px;
  width: 100%;
}

.casestudy-card .casestudy-card-content p {
  font-size: 1.4rem;
  font-weight: 400;
  color: black;
  padding-bottom: 10px;
}
.casestudy-card .casestudy-card-content .header-image img {
  width: 100%;
  object-fit:cover;
  border-radius: 20px;
  height: fit-content;
  /* width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 20px; */
}

@media (max-width: 768px) {
  .expertise-wrapper {
    height: auto;
  }
  .casestudy-card .casestudy-card-content .header-image img {
    height: fit-content;
  }
}
