.video-testimony-wrapper {
    padding-top: 40px;
    margin-bottom: 50px;
}

.video-testimony-wrapper .video-testimony-card {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 200px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 43.22%, #04153A 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}
