.job-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.job-wrap .job-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.job-wrap .job-cont h2 {
  color: #04153a;
  font-size: 3rem;
  font-weight: 700;
}

.job-wrap .job-cont h1 {
  color: #04153a;
  font-size: 1.7rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.job-item {
  margin-bottom: 20px;
}

.available-job {
  font-weight: bold;
  padding: 10px;
  color:black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(212, 203, 203);
}
.job-area {
    margin-top: 30px;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }

.see-job {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 150px;
    background-color: #04153A;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 500;
}
.available-job p {
  font-size: 1.7rem;
  font-weight: 500;
}
.job-area p {
  font-size: 1.7rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer {
  display: none;
  padding: 10px;
  border-left: 4px solid #04153a;
  background-color: #f9f9f9;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: justify;
}
.answer p{
 
  font-size: 1.7rem;
    font-weight: 500;
    text-align: justify;
}

.answer.expanded {
  display: block;
}

.toggle-button {
  cursor: pointer;
}
.question-wrapper {
  background: url('../../../assets/dev-head.webp') center/cover no-repeat;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.question-wrapper .start-wrapper p {
color: white;
font-weight: 900;
font-size: 2.5rem;
text-align: center;
}
.question-wrapper .start-wrapper {
 gap: 20px;
  }

.question-wrapper .start-wrapper .question-head {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fffcfc;
  width: 70%;
  text-align: center;

}
.question-wrapper .start-wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 200px;
  background-color: #ffff;
  color: black;
  border: none;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}