
.serviceslisting .strategy {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .serviceslisting .strategy .strategy-detail h6 {
    color: #445164;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 32.8px;
    text-align: justify;
  }

.serviceslisting .title  {
  color: #04153A;
  font-size: 1.8rem;
  font-weight: bold;
}
.serviceslisting .benefit-detail{
    margin-top: 15px;
}

.serviceslisting  .description {
    color: #445164;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 32.8px;
    text-align: justify;
    margin: 0px;
    
}
.contact-wrapper {
  background: url('../../../assets/dev-head.webp') center/cover no-repeat;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-wrapper .start-contact p {
color: white;
font-weight: 900;
font-size: 2.5rem;
text-align: center;
}
.contact-wrapper .start-contact {
 gap: 20px;
  }

.contact-wrapper .start-contact .contact-head {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fffcfc;
  width: 70%;
  text-align: center;

}
.contact-wrapper .start-contact .contact-head span{
  font-size: 1.6rem;
  font-weight: 400;
  color: #fffcfc;
  width: 70%;
  text-align: center;
  text-decoration-line: underline;

}
.contact-wrapper .start-contact button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 200px;
  background-color: #ffff;
  color: black;
  border: none;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}

  


