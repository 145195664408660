.navbar {
    transition: background-color 0.3s ease, color 0.3s ease;
    height: 80px;
}

.navbar .navbar-nav .nav-item {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 10px;
}
.navbar .navbar-nav .nav-item .nav-link.active {
    color: #02143A;
    font-weight: 700;
}
.navbar .navbar-nav .nav-item.active {
    color: #02143A;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.8px;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #000;
    cursor: pointer;
}

.navbar .navbar-nav .quote-btn {
    border-radius: 6px;
    background: #02143A;
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff !important;
    padding: 1rem;
}

.navbar.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #02143A;
    z-index: 1000;
}
  
.navbar.scrolled .navbar-nav .nav-item .nav-link {
    color: #fff;
}

.navbar.scrolled .navbar-nav .quote-btn {
    background: #fff;
    color: #02143A !important;
}

.navbar.scrolled .navbar-collapse.show .navbar-nav .nav-item .nav-link {
    color: #02143A;
}

.navbar.scrolled .navbar-collapse.show .navbar-nav .quote-btn {
    background: #02143A;
    color: #fff !important;
}
  
  /* Styles for dropdown */
  .nav-item .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .navbar .nav-link.active,
  .navbar .nav-link:hover {
    color: #02143A;
    font-weight: bold;
  } 
  .nav-item.dropdown .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
  }
  
  .dropdown-item:hover,
  .dropdown-item.active {
    background-color: #f8f9fa;
    color: #02143A;
  }
  
  .nav-link.active,
  .dropdown-item.active {
    color: #02143A;
    font-weight: bold;
  }
  
  /* Responsive styles */
  @media (max-width: 991px) {
    .nav-item.dropdown .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: #fff;
      border: 0;
      box-shadow: none;
    }
  
    .nav-item.dropdown .dropdown-menu.show {
      display: block;
    }
  
    .navbar-collapse {
      background-color: #fff;
      padding: 10px;
    }
  
    .navbar-nav .nav-item {
      width: 100%;
    }
  
    .navbar-nav .nav-link {
      width: 100%;
      text-align: left;
    }
  
    .navbar-toggler {
      border: none;
    }
  
    .navbar-toggler:focus {
      outline: none;
    }
  }
  