.strategy-wrapper .strategy-content-wrapper {
    display: flex;
    flex-direction: row;
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card {
    width: 300px;
    height: 120px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-left: 15px;
    border: none;
    border-radius: 20px;
    box-shadow: 30px 24px 48px 27px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    background: #fff;
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card.active {
    border-radius: 14px;
    background: linear-gradient(81deg, #6FEBBF 0%, #638FF6 95%);
    box-shadow: -0.7px 2.9px 53.8px 0px rgba(0, 0, 0, 0.16);
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card img {
    width: 50px;
    height: 50px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card p {
    font-size: 1.8rem;
    font-weight: 600;
}

.strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card i {
    position: relative;
    right: 8px;
    top: 8px;
    align-self: flex-start;
    font-size: 2rem;
    color: #000;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 100px;
    background: #04153A;
    color: #fff;
    border-radius: 20px;
    gap: 30px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 80%;
    color: #fff;
    gap: 7px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-detail h3 {
    font-size: 2.8rem;
    font-weight: 700;
    color: white;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-detail h6 {
    font-size: 1.8rem;
    font-weight: 500;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-detail p {
    font-size: 1.3rem;
    font-weight: 400;
    text-align: justify;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-icons-wrapper .strategyIcon-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.strategy-wrapper .strategy-content-wrapper .strategy-content .strategy-icons-wrapper .strategyIcon-box p {
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 768px) {
    .strategy-wrapper .strategy-content-wrapper {
        flex-direction: column;
        gap: 15px;
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards {
        flex-direction: row;
    }
    
    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card img {
        width: 35px;
        height: 35px;
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card p {
        font-size: 1.4rem;
        text-align: center;
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card i {
        display: none;
    }
}

@media (max-width: 580px) {
    .strategy-wrapper .strategy-content-wrapper .strategy-content {
        padding: 18px;
    }
    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        padding: 5px;
        /* border: 1px solid black; */
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card img {
        width: 20px;
        height: 20px;
    }

    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card p {
        font-size: 1rem;
        text-align: center;
    }

}

/* @media (max-width: 380px) {
    .strategy-wrapper .strategy-content-wrapper .strategy-cards .strategy-card p {
        display: none;
    }
} */