.casestudy-wrapper {
    background-color: black;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.casestudy-wrapper .star-wrapper p {
    color: #fffcfc;
}

.casestudy-wrapper .section-heading {
    font-size: 5.0rem;
    font-weight: 700;
    color: #fffcfc;
    width: 60%;
    text-align: start;
}

.casestudy-wrapper .cards-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.casestudy-wrapper .cards-wrapper .casestudy-card {
    height: 450px;
    width: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: width 0.3s ease;
    position: relative;
    cursor: pointer;
}

.casestudy-wrapper .cards-wrapper .casestudy-card:hover {
    width: 350px;
}

.casestudy-wrapper .cards-wrapper .casestudy-card .casestudy-card-content .arrow-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    cursor: pointer;
}

.casestudy-wrapper .cards-wrapper .casestudy-card .casestudy-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.casestudy-wrapper .cards-wrapper .casestudy-card .casestudy-card-content h3 {
    font-size: 2.0rem;
    font-weight: bold;
    color: #fff;
    padding: 0;
    text-align: center;
}

.casestudy-wrapper .cards-wrapper .casestudy-card .casestudy-card-content p {
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding-bottom: 10px;
}
@media (max-width: 576px) {
    .casestudy-wrapper {
        padding: 15px;
    }

    .casestudy-wrapper .section-heading {
        font-size: 2.5rem;
        width: 100%;
    }

    .casestudy-wrapper .cards-wrapper .casestudy-card {
        height: 250px;
        width: 100%;
    }

}