.services-header h1 {
  color: #04153a;
  font-size: 6rem;
  font-weight: 700;
  line-height: 90px;
}

.styled-header {
  background-color: #f0f0f0;
  background: url("../../assets/allservice.webp") center/cover no-repeat;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 40px;
}

.styled-header h3 {
  color: #fff;
  font-size: 4.6rem;
  font-weight: 700;
  width: 70%;
}

.styled-header p {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  text-align: justify;
  width: 80%;
}

.serviceslisting-wrapper .service-card {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 200px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.22%, #04153a 100%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.5s;
  position: relative;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(0deg);
}

.serviceslisting-wrapper .service-card:hover {
  transform: perspective(1000px) rotateY(180deg);
}

.serviceslisting-wrapper .service-card.flipped {
  transform: perspective(1000px) rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-left: 30px;
  color: #fff;
  gap: 15px;
}

.serviceslisting-wrapper .service-card.flipped .card-content {
  transform: perspective(1000px) rotateY(180deg);
}

.serviceslisting-wrapper .service-card.flipped .card-content h3 {
  font-size: 2.2rem;
  font-weight: bold;
}

.serviceslisting-wrapper .service-card.flipped .card-content p {
  font-size: 1.6rem;
  font-weight: 500;
}

.serviceslisting-wrapper .mvp-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #fff;
}

.serviceslisting-wrapper .mvp-card h2 {
  font-size: 2.6rem;
  text-align: center;
  font-weight: 600;
}

.servicesdetail-listing .service-box {
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid #e6e8ec;
  border-radius: 10px;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.servicesdetail-listing .service-box h3 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.servicesdetail-listing .service-box p {
  font-size: 1.4rem;
}

.serivce-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.serivce-data .data-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serivce-data .data-heading h3 {
  color: #000;
  font-size: 3.8rem;
  font-weight: bold;
}

.serivce-data .data-heading p {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  width: 70%;
  text-align: center;
}

.serivce-data .container {
  display: flex;
  justify-content: center;
}

.serivce-data .data-banner {
  width: 80%;
  height: auto;
  max-width: 100%;
}

.serivce-data .data-banner img {
  width: 100%;
  height: auto;
}

.serivce-data .data-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
}

.serivce-data .data-detail p {
  color: #000;
  font-size: 1.6rem;
  font-weight: 500;
  width: 70%;
  text-align: start;
}
.serviceslisting-wrapper .strategy-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.serviceslisting-wrapper .strategy-content .strategy-detail h3 {
  font-size: 2.8rem;
    font-weight: 700;
  color: black;
}

.serviceslisting-wrapper .strategy-content .strategy-detail h6 {
  color: #445164;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 32.8px;
  text-align: justify;
}

.serviceslisting-wrapper .strategy-content .strategy-detail p {
  font-size: 1.3rem;
  font-weight: 400;
  color: black;
}
.expertise-wrapper {
  background: url("../../assets/expertise.webp") center/cover no-repeat;
  height: 750px;
  width: 100%;
  margin-top: 50px;
  padding: 80px 50px;
}

@media (max-width: 768px) {
  .expertise-wrapper {
    height: auto;
  }
}

.expertise-wrapper .expertise-header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
  padding-top: 40px;
  gap: 30px;
}

.expertise-wrapper .expertise-header h2 {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
}

.expertise-wrapper .expertise-header p {
  font-size: 2rem;
  font-weight: 500;
  color: #fffcfc;
  width: 80%;
  padding-bottom: 40px;
  text-align: justify;
}

.expertise-wrapper .expertise-header .expertise-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 200px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 2rem;
  font-weight: 500;
}

.expertise-wrapper .techIcons-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.expertise-wrapper .techIcons-wrapper .icon-card {
  display: flex;
  align-items: center;
  border-radius: 120px;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgb(77, 67, 86);
  row-gap: 10px;
  width: 250px;
  height: 250px;
}
.expertise-wrapper .techIcons-wrapper .icon-card h1 {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}
.expertise-wrapper .techIcons-wrapper .icon-card h6 {
  color: white;
  font-weight: 900;
  font-size: 4rem;
  text-align: center;
}
.expertise-wrapper .techIcons-wrapper .icon-card h2 {
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}



/* Services Description */
.serviceslisting-wrapper .header-row .header-content {
    display: flex;
    flex-direction: column;
}
.serviceslisting-wrapper .header-row .header-content .content {
  flex-direction: column;
  display: flex;
  border-bottom: 1px solid  #d2d3d7;
  height: auto;
  margin: 10px;
  padding: 5px;

}
.serviceslisting-wrapper .header-row .header-content .content .content-header {
  flex-direction: row;
  display: flex;
  align-items: center;
  /* border: 2px solid black; */

}
 .con-con {
  display: flex;
  justify-content:flex-end;
  /* border: 2px solid black; */
  

}
.serviceslisting-wrapper .header-row .header-content .content p {
  color: #04153A;
  font-size: 1.8rem;
  font-weight: bold;

}
.serviceslisting-wrapper .header-row .header-content .content .detail {
  color: #445164;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 32.8px;
  text-align: justify;

}

.serviceslisting-wrapper  .header-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.toggle-button {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.expandable-container {
  display: flex;
  flex-direction: column;
}

.expandable-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.expandable-content.expanded {
  max-height: 500px; /* Adjust this value based on your content */
  opacity: 1;
}





/* benefits component */




.benefit {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: row;

}

.star-box {
    width: 30px; 
    height: 30px;
    margin-right: 10px;
    border-radius: 30px;
}
.star {
  width: 10px; /* Adjust size as needed */
    height: 10px; /* Adjust size as needed */
    margin-right: 10px; /* Adjust spacing as needed */
}
.benefit-header {
  display: flex;
  align-items: center;
}

.title {
    font-size: 2.0rem;
    font-weight: bold;
    margin: 0;
}

.description {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: justify;
    margin: 0 0 0 20px;
}
.strategy-detail  h3 {
  font-size: 2.8rem;
  font-weight: 700;
    color: black;
  }
  .strategy-detail  .benefit-content p{
    color: #445164;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 32.8px;
  text-align: justify;
  }
  .strategy-detail  .benefit-content h1 {
    color: #04153A;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .benefit-content {
    margin-bottom: 10px;
}


@media (max-width: 580px) {
  .services-header h1 {
    color: #04153a;
    font-size: 5rem;
    font-weight: 700;
    line-height: 70px;
  }
}