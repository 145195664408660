.career-wrapper .header-carrer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.career-wrapper .header-carrer h1 {
  color: #04153A;
  font-size: 5rem;
  font-weight: 700;
  line-height: 80px;
}

.career-wrapper .header-carrer .header-text {
    color: #445164;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 32.8px;
}

.career-wrapper .header-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
}
.career-wrapper .header-carrer .trust-btn-wrapper .career-button {
    width: 150px;
    height: 40px;
    background-color: transparent;
    border: 1px solid black;
    color: green;
    border-radius: 6px;
    font-size: 1.2rem;
}
.career-wrapper .header-carrer .buttons-wrap button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 250px;
    background-color: #04153A;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 500;

}
.career-wrapper .header-carrer .career-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.career-wrapper .header-carrer  .buttons-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
@media (max-width: 768px) {
    .pricing-wrapper .pricing-detail-box {
        border-radius: 0 0 10px 10px;
    }
    .career-wrapper .header-carrer .buttons-wrap button {
        
        font-size: 1.0rem;
    }

}
.career-wrapper .top-head .top-header {
   width: 30%;
  }
  .career-wrapper .top-head {
    display: flex;
    justify-content: center;
    align-items: center;
   }

.career-wrapper .heading {
    font-size: 2.8rem;
    font-weight: 700;
    color: black;
    justify-content: center;
    display: flex;
    text-align: center;
  }
  .serviceslisting-wrapper .section-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: black;
  }
  
  .life-wrapper .cards-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
  }
  
  .career-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: #04153A;
    border-radius: 10px;
  }
  
  .career-card .career-card-content h3 {
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }
  .career-card .career-card-content {
    flex-direction: column;
    display: flex;
    gap: 5px;
    width: 100%;
  }
  
  .career-card .career-card-content p {
    font-size: 1.4rem;
    font-weight: 400;
    color: white;
    padding-bottom: 10px;
  }
  .header-image img {
    width: 100%;
    height: 350px;
    object-fit:fill;
    border-radius: 20px;
  }
  
  @media (max-width: 768px) {
    .expertise-wrapper {
      height: auto;
    }
    .header-image img {
      height: 200px;
    }
    .career-wrapper .top-head .top-header {
      width: 100%;
     }
  }
  .process-wrapper {
    background: url("../../../assets/expertise.webp") center/cover no-repeat;
    height: auto;
    width: 100%;
    margin-top: 50px;
    padding: 80px 50px;
  }
  .process-wrapper .process-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    margin-top: 200px;
    gap: 10px;
  }
  .process-wrapper .tech-process{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .process-wrapper .process-header h2 {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
  }
  
  .process-wrapper .process-header p {
    font-size: 2rem;
    font-weight: 500;
    color: #fffcfc;
    width: 80%;
    padding-bottom: 40px;
    text-align: justify;
  }
  .process-wrapper .tech-process .icon-card-process h1 {
    color: white;
    font-weight: 700;
    font-size: 2.0rem;
    text-align: center;
  }
  .process-wrapper .tech-process .icon-card-process h6 {
    color: white;
    font-weight: 700;
    font-size: 2.0rem;
    text-align: center;
  }
  .process-wrapper .tech-process .icon-card-process h2 {
    color: white;
    font-weight: 500;
    font-size: 1.7rem;
    text-align: center;
  }
  .process-wrapper .tech-process .icon-card-process {
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border: 1px solid rgb(77, 67, 86);
    row-gap: 5px;
    width: 230px;
    height: 230px;
  }
  .process-wrapper p {
    color: white;
    font-weight: 900;
    font-size: 4rem;
    text-align: center;
    padding-bottom: 40px;
  }







  .life-wrapper {
    background-color: black;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.life-wrapper .star-wrapper p {
  color: white;
  font-weight: 900;
  font-size: 4rem;
  text-align: center;
}

.life-wrapper .section-head {
    font-size: 1.6rem;
    font-weight: 400;
    color: #fffcfc;
    width: 60%;
    text-align: center;
    margin-top: 20px;

}
.life-wrapper .life-img {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;

}
.life-wrapper .life-img img {
  height: 300px;
  width: 300px;
  border-radius: 30px;
  object-fit: cover;
  transform: rotate(+10deg);
}

