.process-cycle-wrapper .gif-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

}

.process-cycle-wrapper .gif-wrapper .gif-image {
    max-width: 100%; 
}
