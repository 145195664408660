.experty-wrapper {
    background: url("../../../../assets/expertise.webp") center/cover no-repeat;
    height: 750px;
    width: 100%;
    margin-top: 50px;
    padding: 80px 50px;
}

@media (max-width: 768px) {
    .experty-wrapper {
        height: auto;
    }
}

.experty-wrapper .experty-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: 20px;
}

.experty-wrapper .experty-header h2 {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
}

.experty-wrapper .experty-header p {
    font-size: 1.7rem;
    font-weight: 400;
    color: #fffcfc;
    width: 88%;
    /* padding-bottom: 40px; */
    text-align: justify;
}

.experty-wrapper .experty-header .experty-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    font-size: 2.0rem;
    font-weight: 500;
}

.experty-wrapper .expertyIcons-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
}

.experty-wrapper .expertyIcons-wrapper .experty-card {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
}