.footprint-wrapper {
    background: url("../../../assets/footprint.webp") center/cover no-repeat ;
    height: 600px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
    padding: 50px;
}

.footprint-wrapper .contact-wraper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.footprint-wrapper .contact-wraper i {
    font-size: 24px;
    color: #000;
}

.footprint-wrapper .contact-wraper h6 {
    font-size: 2.2rem;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}

.footprint-wrapper h2 {
    font-size: 2.0rem;
    font-weight: 700;
    color: #000;
}
.footprint-wrapper p {
    font-size: 1.4rem;
    font-weight: 500;
    color: #000;
}