
.head p {
  border: 1.5px solid #04153A;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  }




.header-wrap .header-cont {
    display: flex;
    flex-direction: column;
}

.header-wrap .header-cont h1 {
    color: #04153A;
    font-size: 4rem;
    font-weight: 700;
    line-height: 80px;
}

.header-wrap .header-cont h1 span {
    color: #fff;
    background: #04153A;
    border-radius: 5px;
    padding: 15px 20px;
}

  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .question {
    font-weight: bold;
    cursor: pointer;
    position: relative;
    padding: 10px;
    background-color: #04153A;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question i{
    font-size: 2rem;
    font-weight: bold;
  }
  .question p{
    font-size: 1.7rem;
    font-weight: 500;
  }
  
  .answer {
    display: none;
    padding: 10px;
    border-left: 4px solid #04153A;
    background-color: #f9f9f9;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: justify;
    
  }
  
  .answer.expanded {
    display: block;
  }
  
  .toggle-button {
    cursor: pointer;
  }
  