.story-wrapper .story-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.story-wrapper .story-content h1 {
    color: #04153A;
    font-size: 3.8rem;
    font-weight: 700;
    text-align: justify;
}


.story-wrapper .story-content .story-text {
    color: #445164;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: justify;
    line-height: 32.8px;
}

.story-wrapper .story-content .trust-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}



.story-wrapper .story-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
@media (max-width:580px) {
    .story-wrapper .story-content h1 {
        color: #04153A;
        font-size: 3.0rem;
        font-weight: 700;
    }
    
}