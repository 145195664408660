.portfolio-wrapper {
    margin-top: 50px;
    background-color: #04153A;
    color: #ffffff;
    padding-bottom: 30px;
}

.portfolio-wrapper .star-wrapper {
    padding: 50px;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.portfolio-wrapper .star-wrapper .portfolio-head {
    display: flex;
    flex-direction: row;
    align-items: start;
}

.portfolio-wrapper .star-wrapper h3{
    font-size: 3.6rem;
    color: #fff;
    font-weight: 800;
}

.portfolio-wrapper .star-wrapper .portfolio-btn {
    width: 150px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 6px;
    font-size: 1.2rem;
}

.portfolio-wrapper .portfolio-list {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    gap: 5px;
    height: 250px;
}

.portfolio-wrapper .portfolio-list h4 {
    font-size: 2.0rem;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    text-align: left;
    transition: color 0.3s, border-bottom 0.3s;
    position: relative;
}

.portfolio-wrapper .portfolio-list h4.selected-category {
    color: #4caf50;
    border-bottom: 2px solid #4caf50;
}

.portfolio-wrapper .portfolio-list h4.selected-category::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #4caf50;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
}

.portfolio-wrapper .portfolio-list h4.selected-category:hover::after,
.portfolio-wrapper .portfolio-list h4:hover::after {
    transform: scaleX(1); 
    transform-origin: bottom left;
}

.portfolio-wrapper .portfolio-data .portfolio-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: none;
    border-radius: 10px;
    color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 43.22%, #04153A 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.5s;
    position: relative;
}

.portfolio-wrapper .portfolio-data .portfolio-card:hover .portfolioCard-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;}

.portfolio-wrapper .portfolio-data .portfolio-card .portfolioCard-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White with 70% opacity */
    border-radius: 15px;
    display: none;
    color: #000;
}
@media (max-width: 768px) {
    .portfolio-wrapper {
        height: auto;
        width: auto;
    }
}